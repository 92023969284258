// src/api/index.js

import apiBase from '../apiBase'; // Import the configured axios instance
import menuApi from './menuAPI'; // Import the menu API functions

// Define API functions for users
const userApi = {
  getUsers: () => apiBase.get('/users'),
  getUserById: (id) => apiBase.get(`/users/${id}`),
  createUser: (user, config) => apiBase.post('/users', user, config),
  updateUser: (userId, updates, config) => {
    return apiBase.put(`/users/${userId}`, updates, config);
  },
  deleteUser: (id) => apiBase.delete(`/users/${id}`),
  resendPasswordEmail: (email) =>
    apiBase.post('/users/resend-password', { email }),
  setPassword: (token, newPassword) =>
    apiBase.patch(`/users/set-password/${token}`, { newPassword }),
  changePassword: (id, currentPassword, newPassword) =>
    apiBase.post(`/users/change-password/${id}`, {
      currentPassword,
      newPassword,
    }),
};

// Define API functions for roles
const roleApi = {
  fetchRoles: () => apiBase.get('/roles'),
  getRoleById: (id) => apiBase.get(`/roles/${id}`),
  createRole: (role, config) => apiBase.post('/roles', role, config),
  updateRole: (id, role, config) => apiBase.put(`/roles/${id}`, role, config),
  deleteRole: (id) => apiBase.delete(`/roles/${id}`),
};

// Define API functions for authentication
const authApi = {
  login: (credentials) => apiBase.post('/auth/login', credentials),
  register: (user, config) => apiBase.post('/auth/register', user, config),
  logout: () => apiBase.post('/auth/logout'),
  getCurrentUser: (token) =>
    apiBase.get('/auth/me', {
      headers: { Authorization: `Bearer ${token}` },
    }),
  validateToken: (token) => apiBase.get(`/auth/validate-token/${token}`),
};

// Define API functions for organizations
const organizationApi = {
  getOrganizationsPublic: (organization = '') => {
    return apiBase.post('/organizations/pub', { orgId: organization });
  },
  getOrganizations: () => {
    return apiBase.get('/organizations');
  },
  getOrganizationById: (id) => {
    return apiBase.get(`/organizations/${id}`);
  },
  createOrganization: (organization, config) => {
    return apiBase.post('/organizations', organization, config);
  },
  updateOrganization: (id, updates, config) => {
    return apiBase.put(`/organizations/${id}`, updates, config);
  },
  deleteOrganization: (id) => {
    return apiBase.delete(`/organizations/${id}`);
  },
};

// Define API functions for menu items
const menuItemApi = {
  getMenuItems: () => apiBase.get('/menuItems'),
  getMenuItemById: (id) => apiBase.get(`/menuItems/${id}`),
  createMenuItem: (menuItem, config) =>
    apiBase.post('/menuItems', menuItem, config),
  updateMenuItem: (id, updates, config) =>
    apiBase.put(`/menuItems/${id}`, updates, config),
  deleteMenuItem: (id) => apiBase.delete(`/menuItems/${id}`),
};

// Define API functions for orders
const foodOrderApi = {
  fetchFoodOrders: (loadHistoricOrders) =>
    apiBase.get(`/food-orders?loadHistoricOrders=${loadHistoricOrders}`),
  fetchPreMadeOrders: (orderDate) =>
    apiBase.get(`/food-orders/premade/${orderDate}`),
  getFoodOrderById: (id) => apiBase.get(`/food-orders/${id}`),
  createFoodOrder: (o, config) => apiBase.post('/food-orders', o, config),
  updateFoodOrder: (id, updates, config) =>
    apiBase.put(`/food-orders/${id}`, updates, config),
  deleteFoodOrder: (id) => apiBase.delete(`/food-orders/${id}`),
  updateOrderStatus: (id, status) =>
    apiBase.put(`/food-orders/${id}/status`, { status }),
};

// Define API functions for schools
const schoolApi = {
  getSchoolsPublicByOrgId: (orgId) => {
    if (!orgId) {
      throw new Error('Organization ID is required');
    }
    return apiBase.get(`/schools/pub/${orgId}`);
  },
  getSchools: () => apiBase.get('/schools'),
  getSchoolById: (id) => apiBase.get(`/schools/${id}`),
  createSchool: (school, config) => apiBase.post('/schools', school, config),
  createSchools: (organization, schools, config) => {
    const data = { organization, schools };
    apiBase.post('/schools/bulk', data, config);
  },
  updateSchool: (id, updates, config = null) =>
    apiBase.put(`/schools/${id}`, updates, config),
  deleteSchool: (id) => apiBase.delete(`/schools/${id}`),
};

// Define API functions for reporting
const reportApi = {
  getWeeklyPremadeSummary: (startDate, endDate) =>
    apiBase.get(`/reports/weekly-premade-summary`, {
      params: { startDate, endDate },
    }),
  getWeeklyGroceryOrders: (weekOf, mode) =>
    apiBase.get(`/reports/weekly-grocery-orders`, {
      params: { weekOf, mode },
    }),
  getWeeklyShoppingLists: (startDate, endDate) =>
    apiBase.get(`/reports/weekly-shopping-lists`, {
      params: { startDate, endDate },
    }),
  getPremadesPortioning: (startDate, endDate) =>
    apiBase.get(`/reports/premades-portioning`, {
      params: { startDate, endDate },
    }),
  getHistoricActivityReport: (startDate, endDate, showAll) =>
    apiBase.get(`/reports/historic-activity`, {
      params: { startDate, endDate, showAll },
    }),
  getOrdersAndSpendingReport: (startDate, endDate, showAll) =>
    apiBase.get(`/reports/ordersAndSpending`, {
      params: { startDate, endDate, showAll },
    }),

  getMenuItemsReport: (startDate, endDate, showAll) =>
    apiBase.get(`/reports/menu-items-report`, {
      params: { startDate, endDate, showAll },
    }),
};

// Combine all API functions into a single object
const apiServices = {
  userApi,
  roleApi,
  authApi,
  organizationApi,
  menuItemApi,
  menuApi,
  foodOrderApi,
  schoolApi,
  reportApi,
};

// Export the combined object
export default apiServices;
